<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar
      :loading="loadingReal"
      :action-mode="actionMode"
      :title="title"
    >
      <template #action>
        <toolbar-modify-action
          :expanded="expanded"
          deletable
          @close="tryToCloseEditMode"
          @delete="onDelete"
          @save="onSave"
        />
      </template>

      <template
        #buttons
      >
        <v-btn
          v-if="!expanded"
          icon
          @click="onModifyModeClicked"
        >
          <!--<v-icon>mdi-file-document-edit</v-icon>-->
          <img src="@/assets/images/main/svg/button_Edit.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <additional-information-wrapper :no-bottom="editMode">
        <template v-if="!loading">
          <template v-if="!!workorderId">
            <detail-context-menu
              :location="location"
              :tel-no="formData.customerMobileno"
              :title="formData.accountName"
            />
            <m-form
              ref="form"
              :edit.sync="editMode"
              :form-data.sync="formData"
              :form-info="formInfo"
              class="overflow-y-auto overflow-x-hidden"
              form-provider="mps"
            />
          </template>
          <template v-else>
            <invalid-data />
          </template>
        </template>
        <template v-else>
          <progress-loading />
        </template>
        <template v-if="!!workorderId">
          <!-- 부가 정보 -->
          <additional-information-view
            v-show="!editMode"
            :expand="expanded"
          >
            <template
              #header
              style="padding-bottom: 150px"
            >
              <additional-information-view-header
                :expand.sync="expanded"
              >
                <as-detail-additional-information-header
                  :account-id="accountId"
                  :expand="expanded"
                  :workorder-id="workorderId"
                />
              </additional-information-view-header>
            </template>
            <template #content>
              <additional-information-view-content :expand="expanded">
                <as-detail-additional-information-content
                  :account-id="accountId"
                  :workorder-id="workorderId"
                  :expanded="expanded"
                  :monitoring-printer-id="monitoringPrinterId"
                />
              </additional-information-view-content>
            </template>
          </additional-information-view>
        </template>
        <v-card
          v-if="!loading && work && work.raw && work.raw.workorderId"
          v-show="!expanded && !editMode"
          id="create"
        >
          <as-speed-dialog
            :item="work"
            :showing="!expanded && !editMode"
          />
          <div style="margin-top: 80px;" />
        </v-card>
      </additional-information-wrapper>
    </div>
    <over-max-size
      :base-param="formData"
      :detachments-list="[
        requestFileData.workorderContentDettachments,
        requestFileData.asContentDettachments,
        requestFileData.memoDettachments]"
      :attachments-list="[
        requestFileData.workorderContentAttachments,
        requestFileData.asContentAttachments,
        requestFileData.memoContentAttachments]"
    />
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyToolBar from "@/components/app/toolbar/MyToolBar";
import MForm from "@/assets/plugins/mps-form/MForm";
import {CODE, MAXLENGTH, PATH} from "@/constants";
import ToolbarModifyAction from "@/components/app/toolbar/ToolbarModifyAction";
import VuexProps from "@/framework/vuex/VuexProps";
import InvalidData from "@/components/part/InvalidData";
import ProgressLoading from "@/components/part/ProgressLoading";
import DetailUtil from "@/components/mixins/DetailUtil";
import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
import AdditionalInformationViewContent from "@/components/widgets/additional/AdditionalInformationViewContent";
import AdditionalInformationViewHeader from "@/components/widgets/additional/AdditionalInformationViewHeader";
import AsDetailAdditionalInformationHeader
  from "@/components/widgets/additional/slot/AsDetailAdditionalInformationHeader";
import AsDetailAdditionalInformationContent
  from "@/components/widgets/additional/slot/AsDetailAdditionalInformationContent";
import AdditionalInformationWrapper from "@/components/widgets/additional/AdditionalInformationWrapper";
import AdditionalInformationView from "@/components/widgets/additional/AdditionalInformationView";
import DetailContextMenu from "@/components/widgets/additional/DetailContextMenu";
import REGEX from "@/constants/regex";
import AsMultiFileUploadMixin from "@/components/windows/as/mixins/AsMultiFileUploadMixin";
import {ETC} from "@/constants/etc-code";
import BackGuardEditModeInterface from "@/components/windows/mixins/BackGuardEditModeInterface";
import AsSpeedDialog from "@/components/part/as/speed-dialog/AsSpeedDialog";
import Work from "@/model/work";
import OnWorkorderStatusUpdatedListener from "@/framework/listeners/OnWorkorderStatusUpdatedListener";
import OverMaxSize from "@/components/app/toolbar/OverMaxSize.vue";

const infoKeys = {
  account: "accountId",
  branch: "branchId",
  printer: "printerModelName",
  customerName: "customerName",
  customerMobileno: "customerMobileno",
  customerTelno: "customerTelno",
  customerEmail: "customerEmail",
  asReceiptType: "asReceiptType",
  reasonCode: "reasonCode",
  doneReasonCode: "doneReasonCode",
  staffUId: "staffUId",
  workorderState: "workorderState",
  workorderReceiptDate: "workorderReceiptDate",
  reservationDatetime: "reservationDatetime",
  doneDate: "doneDate",
  address: "address",
  addressDetail: "addressDetail",
  workorderContent: "workorderContent",
  memo: "memo",
  asContent: "asContent",
  customerSign: "customerSign",
  receiptionistName: "receiptionistName",
  partnerName: "partnerName",
  workorderHistory: "WorkorderHistory",
  accountAsLastHistory: "AccountAsLastHistory",
  contractList: "ContractList",
  installLocation: "installLocation",
  installLocation2: "installLocation2",
}

export default {
  name: "AsDetailComp",
  components: {
    AsSpeedDialog,
    DetailContextMenu,
    AdditionalInformationView,
    AdditionalInformationWrapper,
    AsDetailAdditionalInformationContent,
    AdditionalInformationViewContent,
    AsDetailAdditionalInformationHeader,
    AdditionalInformationViewHeader,
    ProgressLoading,
    InvalidData,
    ToolbarModifyAction, MForm, MyToolBar, OverMaxSize
  },
  extends: WindowComponent,
  mixins: [VuexProps, DetailUtil, AsMultiFileUploadMixin, BackGuardEditModeInterface, OnWorkorderStatusUpdatedListener],
  props: {
    workorderId: undefined,
  },
  data() {
    return {
      expanded: false,
      loading: false,
      loadingReal: false,
      updating: false,
      actionMode: false,
      requestFileData: {
        workorderContentDettachments: null,
        asContentDettachments: null,
        memoDettachments: null,
        workorderContentAttachments: null,
        asContentAttachments: null,
        memoContentAttachments: null,
      },
      formData: undefined,
      formInfo: [
        {
          component: GroupHeader,
          propsData: {
            title: this.$translate("A/S 정보"),
          },
        },
        {
          mutable: true,
          title: this.$translate('거래처명'),
          key: infoKeys.account,
          read: (inEditMode, data) => null,
          write: (data, v) => {
            if (!v) return;
            data.accountId = v.accountId;
            data.accountName = v.accountName;
            data.accountBranchId = null;
            data.branchName = null;
            data.printerModelName = null;
            data.printerSerialNo = null;
            data.monitoringPrinterId = 0;
            // data.address = v.address;
            // data.zipCode = v.zipCode;
            // data.addressDetail = v.addressDetail;
            // data.customerName = v.chargeFullname;
            // data.customerMobileno = v.chargeMobileno;
            // data.customerTelno = v.chargeTelno;
            // data.customerEmail = v.chargeEmail;
          },
          formReadType: this.$mps.form.types.ACCOUNT_PICKER,
          formWriteType: this.$mps.form.types.ACCOUNT_PICKER,
        },
        {
          mutable: true,
          title: this.$translate('영업점명'),
          key: infoKeys.branch,
          disable: (v, data) => !data.accountId,
          read: (inEditMode, data) => inEditMode ? null : data.branchName,
          write: (data, v) => {
            if (!v) return;
            data.accountBranchId = v.accountBranchId;
            data.branchName = v.branchName;
            data.printerModelName = null;
            data.printerSerialNo = null;
            data.monitoringPrinterId = 0;
            // data.address = v.address;
            // data.zipCode = v.zipCode;
            // data.addressDetail = v.addressDetail;
            // data.customerName = v.chargeFullname;
            // data.customerMobileno = v.chargeMobileno;
            // data.customerTelno = v.chargeTelno;
            // data.customerEmail = v.chargeEmail;
          },
          formReadType: this.$mps.form.types.BRANCH_PICKER,
          formWriteType: this.$mps.form.types.BRANCH_PICKER,
        },
        {
          mutable: true,
          title: this.$translate('프린터 모델'),
          key: infoKeys.printer,
          read: (inEditMode, data) => inEditMode ? null : data.printerModelName,
          write: (data, v) => {
            if (!v) return;
            data.printerModelName = v.printerModelName;
            data.printerSerialNo = v.printerSerialNo;
            data.monitoringPrinterId = v.monitoringPrinterId || 0;
          },
          formReadType: this.$mps.form.types.PRINTER_PICKER,
          formWriteType: this.$mps.form.types.PRINTER_PICKER,
          rules: [
            // (data) => !!data.printerModelName || this.$translate('프린터 모델을 입력해 주세요'),
          ],
        },
        {
          mutable: false,
          title: this.$translate('설치위치') + '<br />' + '(' + this.$translate('프린터') + ')',
          key: infoKeys.installLocation,
          if: (inEditMode) => !inEditMode,
        },
        {
          mutable: false,
          title: this.$translate('설치위치') + '<br />' + '(' + this.$translate('계약서') + ')',
          key: infoKeys.installLocation2,
          if: (inEditMode) => !inEditMode,
        },
        {
          mutable: true,
          title: this.$translate('AS진행상태'),
          key: infoKeys.workorderState,
          read: (inEditMode, data) => {
            return {
              codeKey: data.workorderState,
              codeValue: data.workorderStateName
            }
          },
          write: (data, v) => {
            if (!v) return;
            data.workorderStateName = v.codeValue;
            data.workorderState = v.codeKey;
          },
          formReadType: this.$mps.form.types.CODE_PICKER,
          formWriteType: this.$mps.form.types.CODE_PICKER,
          options: {
            codeType: "ST",
          },
          rules: [
            (data) => !!data.workorderState || this.$translate('AS진행상태을 선택해 주세요'),
          ],
        },
        {
          mutable: true,
          title: this.$translate('접수 유형'),
          key: infoKeys.workorderReceiveType,
          read: (inEditMode, data) => {
            return {
              codeValue: CODE.WORKORDER_RECEIVE_TYPE_NAME[data.workorderReceiveType] || CODE.WORKORDER_RECEIVE_TYPE_NAME.NULL,
              codeKey: data.workorderReceiveType,
            }
          },
          write: (data, v) => {
            if (!v) return;
            data.workorderReceiveType = v.codeKey;
          },
          formReadType: this.$mps.form.types.OPTIONS_PICKER,
          formWriteType: this.$mps.form.types.OPTIONS_PICKER,
          options: {
            items: CODE.WORKORDER_RECEIVE_TYPE_ITEMS,
          },
        },
        {
          mutable: true,
          title: this.$translate('AS_기사'),
          key: infoKeys.staffUId,
          read: (inEditMode, data) => {
            return {
              userId: data.staffUId || data.worker,
              userFullname: data.staffName
            }
          },
          write: (data, v) => {
            if (!v) return;
            data.staffName = v.userFullname;
            data.staffUId = v.userId;
          },
          formReadType: this.$mps.form.types.STAFF_PICKER,
          formWriteType: this.$mps.form.types.STAFF_PICKER,
        },
        {
          mutable: true,
          title: this.$translate('예약일시'),
          key: infoKeys.reservationDatetime,
          write: (data, v) => {
            const d = this.$parseDate(v);
            data.reservationDatetime = !!d ? d.toISO() : null
          },
          formReadType: this.$mps.form.types.DATETIME_PICKER,
          formWriteType: this.$mps.form.types.DATETIME_PICKER,
          options: {
            canNotSelectPast: true,
          },
        },
        {
          mutable: true,
          title: this.$translate('증상 상세'),
          key: infoKeys.workorderContent,
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
          updateData: (v) => {
            this.requestFileData.workorderContentAttachments = v.attachments;
            this.requestFileData.workorderContentDettachments = v.dettachments;
          },
        },
        {
          mutable: true,
          title: this.$translate('처리 내용'),
          key: infoKeys.asContent,
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
          updateData: (v) => {
            this.requestFileData.asContentAttachments = v.attachments;
            this.requestFileData.asContentDettachments = v.dettachments;
          },
        },
        {
          mutable: true,
          title: this.$translate('주소'),
          key: infoKeys.address,
          read: (inEditMode, data) => null,
          write: (data, v) => {
            data.address = v.roadAddr || v.jibunAddr;
            data.zipCode = v.zipNo;
            data.addressDetail = null;
            data.lat = v.lat;
            data.lng = v.lng;
          },
          formReadType: this.$mps.form.types.ADDRESS_PICKER,
          formWriteType: this.$mps.form.types.ADDRESS_PICKER,
        },
        {
          mutable: true,
          title: this.$translate('상세주소'),
          key: infoKeys.addressDetail,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {
            maxlength: MAXLENGTH.ADDRESS.DETAIL,
          },
        },
        {
          component: GroupHeader,
          propsData: {
            title: this.$translate("A/S 상세"),
          },
        },
        {
          mutable: true,
          title: this.$translate('AS접수_구분'),
          key: infoKeys.asReceiptType,
          read: (inEditMode, data) => {
            return {
              codeValue: data.asReceiptTypeName,
              codeKey: data.asReceiptType
            }
          },
          write: (data, v) => {
            if (!v) return;
            data.asReceiptTypeName = v.codeValue;
            data.asReceiptType = v.codeKey;
          },
          formReadType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
          formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
          options: {
            codeType: "ART",
          },
          rules: [
            // (data) => !!data.asReceiptType || this.$translate('AS접수 구분을 선택해 주세요'),
          ],
        },
        {
          mutable: true,
          title: this.$translate('AS접수_증상'),
          key: infoKeys.reasonCode,
          read: (inEditMode, data) => {
            return {
              codeValue: data.reasonCodeName,
              codeKey: data.reasonCode
            }
          },
          write: (data, v) => {
            if (!v) return;
            data.reasonCodeName = v.codeValue;
            data.reasonCode = v.codeKey;
          },
          formReadType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
          formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
          options: {
            codeType: "AC",
          },
          rules: [
            // (data) => !!data.reasonCode || this.$translate('AS접수 증상을 선택해 주세요'),
          ],
        },
        {
          mutable: true,
          title: this.$translate('처리유형'),
          key: infoKeys.doneReasonCode,
          read: (inEditMode, data) => {
            return {
              codeValue: data.doneReasonCodeName,
              codeKey: data.doneReasonCode
            }
          },
          write: (data, v) => {
            if (!v) return;
            data.doneReasonCodeName = v.codeValue;
            data.doneReasonCode = v.codeKey;
          },
          formReadType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
          formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
          options: {
            codeType: 'AS',
          }
        },
        {
          mutable: true,
          title: this.$translate('접수일시'),
          key: infoKeys.workorderReceiptDate,
          write: (data, v) => {
            const d = this.$parseDate(v);
            data.workorderReceiptDate = !!d ? d.toISO() : null
          },
          formReadType: this.$mps.form.types.DATETIME_PICKER,
          formWriteType: this.$mps.form.types.DATETIME_PICKER,
          rules: [
            (data) => !!data.workorderReceiptDate || this.$translate('접수일시를 입력해 주세요'),
          ],
        },
        {
          mutable: true,
          title: this.$translate('완료일시'),
          key: infoKeys.doneDate,
          write: (data, v) => {
            const d = this.$parseDate(v);
            data.doneDate = !!d ? d.toISO() : null
          },
          formReadType: this.$mps.form.types.DATETIME_PICKER,
          formWriteType: this.$mps.form.types.DATETIME_PICKER,
        },
        {
          mutable: false,
          title: this.$translate('접수자'),
          key: infoKeys.receiptionistName,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
        },
        {
          mutable: true,
          title: this.$translate('협력업체'),
          key: infoKeys.partnerName,
          read: (inEditMode, data) => null,
          write: (data, v) => {
            data.partnerName = v.codeValue;
          },
          formReadType: this.$mps.form.types.RENTALCOMPANY_CODE_DIALOG_PICKER,
          formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_DIALOG_PICKER,
        },
        {
          mutable: true,
          title: this.$translate('메모'),
          key: infoKeys.memo,
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
          updateData: (v) => {
            this.requestFileData.memoContentAttachments = v.attachments;
            this.requestFileData.memoDettachments = v.dettachments;
          },
        },
        {
          mutable: true,
          title: this.$translate('고객 서명'),
          key: infoKeys.customerSign,
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
        },
        {
          component: GroupHeader,
          propsData: {
            title: this.$translate("거래처 담당자"),
          },
        },
        {
          mutable: true,
          title: this.$translate('담당자'),
          key: infoKeys.customerName,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {
            maxlength: MAXLENGTH.WORK.CUSTOMER.NAME,
          },
        },
        {
          mutable: true,
          title: this.$translate('휴대폰'),
          key: infoKeys.customerMobileno,
          formReadType: this.$mps.form.types.PHONE_NUMBER,
          formWriteType: this.$mps.form.types.PHONE_NUMBER,
          options: {
            canSms: true,
            maxlength: MAXLENGTH.WORK.CUSTOMER.MOBILENO,
          },
        },
        {
          mutable: true,
          title: this.$translate('전화번호'),
          key: infoKeys.customerTelno,
          formReadType: this.$mps.form.types.PHONE_NUMBER,
          formWriteType: this.$mps.form.types.PHONE_NUMBER,
          options: {
            maxlength: MAXLENGTH.WORK.CUSTOMER.TELNO,
          },
        },
        {
          mutable: true,
          title: this.$translate('이메일'),
          key: infoKeys.customerEmail,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {
            type: "email",
            maxlength: MAXLENGTH.WORK.CUSTOMER.EMAIL,
          },
          rules: [
            (data) => !data.customerEmail || RegExp(REGEX.EMAIL).test(data.customerEmail) || this.$translate('올바른 이메일을 입력해주세요'),
          ],
        },
      ],
      alreadyLoaded: false,
    }
  },
  computed: {
    work() {
      if (!this.formData) return null;
      return new Work(this.formData);
    },

    accountId() {
      return this.formData && this.formData.accountId;
    },

    location() {
      return this.formData && {lat: this.formData.lat, lng: this.formData.lng};
    },

    monitoringPrinterId() {
      return this.formData && this.formData.monitoringPrinterId;
    },

  },
  watch: {
    workorderId() {
      this.editMode = false;
      this.processLoadOnce(true);
    },

    formData: {
      deep: true,
      handler: function () {
        this.updateOptions();
        this.updatePropsData();
      }
    },
  },
  mounted() {
    if (!this.alreadyLoaded) {
      this.processLoadOnce(false);
    }

    this.alreadyLoaded = false;
  },
  created() {
    this.processLoadOnce(true);
    this.updatePropsData();
  },
  methods: {
    onWorkorderStatusUpdated() {
      this.processLoadOnce(true);
      this.updatePropsData();
    },

    updatePropsData() {
      // A/S 변경 내역
      this.updatePropsDataByKey(infoKeys.workorderHistory, () => {
        return {
          workorderId: this.workorderId,
        }
      });

      // 미수금액 & 최근 AS 내역
      this.updatePropsDataByKey(infoKeys.accountAsLastHistory, () => {
        return {
          accountId: this.accountId,
        }
      });

      // 계약 목록
      this.updatePropsDataByKey(infoKeys.contractList, () => {
        return {
          accountId: this.accountId,
        }
      });
    },

    updateOptions() {
      // 거래처 피커
      this.updateOptionByKey(infoKeys.account, () => {
        return {
          accountId: this.formData.accountId,
          accountName: this.formData.accountName
        }
      });

      // 영업점 피커
      this.updateOptionByKey(infoKeys.branch, () => {
        return {
          accountId: this.formData.accountId,
          accountName: this.formData.accountName,
          branchName: this.formData.branchName,
          accountBranchId: this.formData.accountBranchId,
        }
      });

      // 프린터 피커
      this.updateOptionByKey(infoKeys.printer, () => {
        return {
          printerModelName: this.formData.printerModelName,
          printerSerialNo: this.formData.printerSerialNo,
          accountId: this.formData.accountId,
          accountBranchId: this.formData.accountBranchId,
          whetherLoadPrinterModels: true,
          whetherLoadPrinters: true,
          whetherLoadUserModels: true,
        }
      });

      // 주소 피커
      this.updateOptionByKey(infoKeys.address, () => {
        return {
          address: this.formData.address,
          zipCode: this.formData.zipCode,
          lat: this.formData.lat,
          lng: this.formData.lng,
        }
      });

      // 협력업체 피커
      this.updateOptionByKey(infoKeys.partnerName, () => {
        return {
          codeType: 'ASPTN',
          codeValue: this.formData.partnerName,
          defaultTitle: "협력업체 선택",
        }
      });
    },

    updateOptionsForFileUploadForm() {
      // 접수증상 상세
      this.updateOptionByKey(infoKeys.workorderContent, () => {
        return {
          attachments: this.attachments.workorderContentAttachments,
          listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
          accept: ETC.ACCEPT.IMAGE,
        }
      });

      // 처리증상 상세
      this.updateOptionByKey(infoKeys.asContent, () => {
        return {
          attachments: this.attachments.asContentAttachments,
          listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
          accept: ETC.ACCEPT.IMAGE,
        }
      });

      // 메모
      this.updateOptionByKey(infoKeys.memo, () => {
        return {
          listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
          attachments: this.attachments.memoAttachments,
          accept: ETC.ACCEPT.IMAGE,
        }
      });

      // 고객 서명
      this.updateOptionByKey(infoKeys.customerSign, () => {
        return {
          listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
          attachments: this.attachments.customerSign,
          accept: ETC.ACCEPT.IMAGE,
          resign: true,
          item: new Work(this.formData),
        }
      });
    },

    processLoadOnce(loading) {
      this.loadDetail(loading);
      this.alreadyLoaded = true;
    },

    async loadDetail(loading = true) {
      this.loading = loading;
      this.loadingReal = true;
      try {
        const response = await this.$request(PATH.WORK_ORDER.DETAIL)
          .setObject({workorderId: this.workorderId, asId: this.asId})
          .wait(500)
          .enqueue();
        this.formData = response;
        try {
          this.toAttachments(this.formData); // 파일 정보를 적절한 모델로 변환
        } catch (e) {
          console.error(e);
          this.$snackbarError(this.$translate("첨부파일을 가져오지 못했습니다."));
        }
        try {
          this.updateOptionsForFileUploadForm(); // 파일 정보를 입력폼에 전달
          this.updateOptions();
        } catch (e) {
          console.error(e);
          this.$snackbarError(this.$translate("옵션 초기화에 실패하였습니다."));
        }
      } catch (e) {
        this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
      }
      this.loading = false
      this.loadingReal = false;
    },

    sanitize() {
      this.formData.workorderReceiptDate = this.utilManager.sanitizeDatetime(this.formData.workorderReceiptDate);
      this.formData.reservationDatetime = this.utilManager.sanitizeDatetime(this.formData.reservationDatetime);
      this.formData.doneDate = this.utilManager.sanitizeDatetime(this.formData.doneDate);
    },

    async updateDetail() {
      this.updating = true;
      try {
        this.sanitize(); // 가끔 접수일시가 luxon datetime 객체로 전송되는 경우가 있어서 Datetime 데이터를 새너타이즈합니다.
        if (this.formData.workorderState === "5" && !this.formData.doneDate) this.formData.doneDate = this.$now().toISO();
        const response = await this.$requestWithOverlay(PATH.WORK_ORDER.UPDATE_NEW)
          .multipart()
          .appendObject('context', this.formData)
          .appendObject("workorderContentDettachments", this.requestFileData.workorderContentDettachments)
          .appendObject("asContentDettachments", this.requestFileData.asContentDettachments)
          .appendObject("memoDettachments", this.requestFileData.memoDettachments)
          .appendFiles("workorderContentAttachments", this.requestFileData.workorderContentAttachments)
          .appendFiles("asContentAttachments", this.requestFileData.asContentAttachments)
          .appendFiles("memoAttachments", this.requestFileData.memoContentAttachments)
          .enqueue();
        this.$snackbar(this.$translate("A/S 정보가 수정되었습니다."));
        this.setEditMode(false);
        this.loadDetail();
        this.backGuard.pop(); // 업데이트에 성공하였다면 편집모드에서 설정된 백가드를 제거합니다.
      } catch (e) {
        console.error(e);
        this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
      }
      this.updating = false;
    },

    async onSave() {
      if (!await this.$refs.form.validate()) return;
      this.updateDetail();
    },

    setEditMode(enabled) {
      this.actionMode = enabled;
      this.editMode = enabled;
      this.initRequestFileData();
    },

    onModifyModeClicked() {
      this.setEditMode(true);
    },

    onDelete() {
      this.$confirm(this.$translate("A/S를 삭제하시겠습니까? 삭제된 A/S는 복구될 수 없습니다."))
        .then(() => {
          this.requestDelete();
        })
        .catch(() => {
          // do nothing
        });
    },
    async requestDelete() {
      try {
        await this.$requestWithOverlay(PATH.AS_DELETE)
          .setObject({workorderId: this.formData.workorderId, asId: this.formData.asId})
          .enqueue();
        this.$snackbarSuccess(this.$translate("삭제되었습니다."));
        this.backGuard.pop(); // 업데이트에 성공하였다면 편집모드에서 설정된 백가드를 제거합니다.
        this.$router.go(-1);
      } catch (e) {
        this.$alert(this.$translate("A/S 삭제중 에러가 발생하였습니다."));
      }
    },

    initRequestFileData() {
      this.requestFileData.workorderContentDettachments = null;
      this.requestFileData.asContentDettachments = null;
      this.requestFileData.memoDettachments = null;
      this.requestFileData.workorderContentAttachments = null;
      this.requestFileData.asContentAttachments = null;
      this.requestFileData.memoContentAttachments = null;
    },
  },
}
</script>

<style scoped>

#create .v-speed-dial {
  position: fixed;
  right: 10px;
  bottom: 80px;
}

#create .v-btn--floating {
  position: relative;
}

/*.v-btn--fab.v-btn--contained {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}*/

</style>
