<template>
  <div style="height: 100%; overflow-y: auto !important;">
    <template v-if="!loading">
      <template>
        <no-items
          v-if="!item"
          :title="'모니터링프린터 정보가 없습니다' | translate"
        />
        <printer-item-card
          v-else
          :item="item"
          :options="{isSimple: true}"
        /><!--
        <contract-list-item
          :items="items"
          @click:contract="onClickContract"
        />-->
      </template>
    </template>
    <template v-else>
      <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
        <progress-loading />
      </div>
    </template>
  </div>
</template>

<script>
import {PATH} from "@/constants";
import NoItems from "@/components/items/NoItems";
import ContractListItem from "@/components/items/contract/ContractListItem";
import Contract from "@/model/contract";
import VuexProps from "@/framework/vuex/VuexProps";
import ProgressLoading from "@/components/part/ProgressLoading";
import Permissions from "@/constants/permissions";
import PrinterItemCard from "@/components/items/printer/PrinterItemCard.vue";
import Printer from "@/model/printer";

export default {
  name: "PrinterTabsItems",
  components: {PrinterItemCard, ProgressLoading, NoItems},
  mixins: [VuexProps],
  props: {
    accountId: {
      type: [Number, String],
      default: undefined,
    },
    changeId: {
      type: Number,
      default: 0,
    },
    monitoringPrinterId: {
      type: [Number, String],
      default: undefined,
    }
  },
  data() {
    return {
      loading: false,
      item: undefined,
    }
  },
  watch: {
    accountId: {
      immediate: true,
      handler() {
        this.loadContractList();
      },
    },
  },
  methods: {
    async loadContractList(loading = true) {
      if (!this.monitoringPrinterId) return;
      if (this.loading) return;
      this.loading = loading;
      try {
        const response = await this.$request(PATH.PRINTER.GET)
          .setObject({monitoringPrinterId: this.monitoringPrinterId})
          .wait(500)
          .enqueue();
        if (!response || !response.data || !response.data.monitoringPrinterId) {
          this.item = undefined
        } else {
          const result = response.data
          const {
            totalColorPageCount,
            totalColorPageCount3,
            totalColorPageCount4,
            totalMonoPageCount,
            totalMonoPageCount3,
            totalMonoPageCount4,
            totalPageCount,
            totalPageCount3,
            totalPageCount4,
            ...others
          } = result
          this.item = new Printer({
            finalColorPageCount: totalColorPageCount,
            finalColorPageCount3: totalColorPageCount3,
            finalColorPageCount4: totalColorPageCount4,
            finalMonoPageCount: totalMonoPageCount,
            finalMonoPageCount3: totalMonoPageCount3,
            finalMonoPageCount4: totalMonoPageCount4,
            finalPageCount: totalPageCount,
            finalPageCount3: totalPageCount3,
            finalPageCount4: totalPageCount4,
            ...others,
          })
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * 계약을 클릭하였습니다. 상세페이지로 이동합니다.
     */
    onClickContract(item) {
      this.backGuard.pop();
      const params = {
        contractId: !item ? 0 : item.raw.contractId,
        contractNo: !item ? 0 : item.raw.contractNo,
        accountId: !item ? this.accountId : item.raw.accountId,
        permission: Permissions.EXPOSE_ACCOUNT_INFO
      }
      this.routerManager.push({
        name: "ContractRegister",
        params
      });
    },
  },
}
</script>

<style scoped>

</style>